import React from "react";
import bannerespecomdig from '../../../img/facultades/com_digital.jpg';
import Pasos from "../../../componentes/programas/Pasos";
import {Contacto} from "../../../componentes/programas/Contacto";
import {Fichatecnica} from "../../../componentes/programas/FichaTecnica";
import gastrocurse from '../../../img/cursos/gastro-course.jpg';
import planeEspComunDig from '../../../img/Plan-estudios/esp-comunicacion-digital.png';


const EspComunDigital =() => {

    const infoEspComunDigital = [
        {
          Ficha: "El Programa de Especialización en Comunicación Digital se distingue por ofrecer una formación avanzada y estratégica en el dinámico campo de la comunicación en línea. Diseñado para profesionales que buscan potenciar sus habilidades en la creación y gestión de contenidos digitales, este programa combina una sólida base teórica con prácticas innovadoras y herramientas de vanguardia. Nuestro Posgrado abarca desde estrategias de marketing digital y análisis de datos hasta la optimización de redes sociales y la creación de campañas interactivas.",
          Perfil:"El Especialista en Comunicación Digital es un profesional altamente capacitado en la creación, gestión y optimización de estrategias de comunicación a través de plataformas digitales. Desarrollar campañas que mejoren la presencia en línea de la marca, aumenten el compromiso del público y generen resultados medibles. Este profesional, combina habilidades creativas con un sólido entendimiento de las herramientas digitales y analíticas para ejecutar estrategias efectivas que alineen los objetivos de comunicación con los de marketing.",
          PerfilOcupacional:"La Especialización en Comunicación Digital de la Fundación Universitaria Horizonte está orientada a profesionales con interés y que buscan conocer sobre medios, plataformas y herramientas digitales, software de edición y plataformas de gestión de contenido. Profesionales que buscan complementar y/o reforzar su conocimientos y formación como comunicadores, diseñadores y humanistas.",
          Denominación: "Especialización en Comunicación Digital",
          Titulo: "Especialista en Comunicación Digital",
          Modalidad: "Presencial",
          Creditos:"26 Créditos",
          Semestres:"2",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "108990",
          Registro: "15261",
          Matricula: "$7.651.700",
          planEstudio: planeEspComunDig,
          videoFondo: gastrocurse,
          Video: "https://www.youtube.com/embed/LXb3EKWsInQ",
          Facultad:"Facultad de Posgrados",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Certificación en Plataformas Digitales",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerespecomdig})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoEspComunDigital.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoEspComunDigital.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default EspComunDigital