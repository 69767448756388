import React from "react";
import bannernegociosinter from '../../img/facultades/nego_internacionales.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_admon_negocios.png';
import planeAdmonNegoInter from '../../img/Plan-estudios/admi-nego-Inter.png';





const AdminNegoInter =() => {

    const infoAdminNegoInter = [
        {
          Ficha: "El Programa de Administración en Negocios Internacionales de la Fundación Universitaria Horizonte se destaca por su enfoque integral y práctico, preparando a los estudiantes para enfrentar los desafíos del mundo empresarial en un contexto global, que va más allá de los aspectos técnicos, adquieren habilidades para adaptarse a los cambios en los entornos económicos, políticos y culturales a nivel nacional e internacional, adquieren conocimientos en áreas como administración, economía, finanzas y producción.",
          Perfil:"El Programa de Administración en Negocios Internacionales forma profesionales con una visión global y habilidades para gestionar negocios en un contexto empresarial internacional, comprenden la importancia de los ambientes interculturales en los procesos de negociación. Esto les permite construir relaciones provechosas con el mundo empresarial, entidades gubernamentales y clientes.",
          PerfilOcupacional:"El Administrador en Negocios Internacionales de la Fundación Universitaria Horizonte podrá desempeñarse como: •	Administrador o coordinador de logística internacional de bienes y/o servicios •	Coordinador, administrador o analista de procesos de exportación o importación. •	Gerente de Compañías Nacionales e Internacionales •	Director de Logística Nacional e Internacional •	Consultor y/o Asesor generando estrategias de penetración de mercados internacionales. •	Emprendedor de su negocio en exportaciones e importación de productos y servicios.",
          Denominación: "Administración de Negocios Internacionales",
          Titulo: "Administrador/a de Negocios Internacionales",
          Modalidad: "Presencial",
          Creditos:"140 Créditos",
          Semestres:"8",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109385",
          Registro: "9888",
          Matricula: "$5.936.650",
          planEstudio: planeAdmonNegoInter,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2a_bFiqJAg/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Con certificación en Marketing y Logística",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannernegociosinter})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoAdminNegoInter.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoAdminNegoInter.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default AdminNegoInter