import React from "react";
import'../css/facultades.css';
import bannercjuridicas from '../../src/img/facultades/bg-juridicos.jpeg';
import {FacultadesCard} from'../componentes/facultades/FacultadesCard';
import derecho from '../img/facultades/derecho.jpg';



const FacultadCienciasJuridicas = () => {

    const facultadcjuridicas = [
        {
          title: "Derecho",
          imgUrl: derecho,
          snies: "SNIES 107213",
          semestre:"10 Semestres" ,
          link:"/facultades/facultadCienciasJuridicas/derecho"
        },
        
      ];

    return(
       <>
    <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannercjuridicas})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

   
    <section id="facultades" className="facultades">
      <div className="container" data-aos="fade-up">

        <div className="row position-relative">


          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
            <h2>Facultad de Ciencias Jurídicas</h2>

            <p>
            Bienvenidos a la Facultad de la Ciencias Jurídicas de la Fundación Universitaria Horizonte, nuestra facultad es un espacio comprometido con la formación integral del profesional en derecho, nuestro programa académico está diseñado para ofrecer una educación sólida y actualizada, que combina la teoría con la práctica, preparando a nuestros estudiantes para enfrentar los retos del mundo jurídico. 
            </p>

            <p>
            En nuestra facultad cuentas con un entorno en constante cambio, donde la justicia y la equidad son fundamentales, nuestra facultad se dedica a cultivar habilidades criticas analíticas y éticas en nuestros alumnos, a través de un enfoque interdisciplinario fomentando la investigación y el pensamiento crítico permitiendo que nuestros estudiantes se conviertan en lideres en el ámbito legal. 
           </p>

           <p>
          Nos enorgullece contar con un cuerpo docente altamente calificado, integrado por profesionales con experiencia en diversas áreas del derecho.
          </p>


        </div>

        <div className="row gy-5 facultades">
        {
                          facultadcjuridicas.map((div, index) => {
                            return (
                              <FacultadesCard
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }

        </div>
        </div>
        </div>
        </section>
        </>
    )
}

export default FacultadCienciasJuridicas