import React from "react";
import banneringsoftware from '../../img/facultades/inge_software.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica";
import panta from '../../img/cursos/panta-ing-sfot.png'
import planeIngSoftw from '../../img/Plan-estudios/ing-software.png'



const IngSoftware =() => {

    const infoingsoftware = [
        {
          Ficha: "El programa Ingeniería de Software de la Fundación Universitaria Horizonte proporciona a los estudiantes experiencias prácticas significativas desarrollando sus aptitudes y actitudes en áreas como Big Data, inteligencia artificial y programación, que le permitirán desempeñarse en el mercado laboral desarrollando sistemas de información a través del manejo de las TIC y diseño de software.",
          Perfil:"El Ingeniero de Software de La Fundación Universitaria Horizonte, cuenta con una formación integral, soportada en el fortalecimiento de las capacidades para analizar, diseñar y desarrollar sistemas de información, software para dispositivos móviles y construir sitios web de acuerdo con las necesidades del cliente para desempeñarse en los diferentes campos del sector empresarial, realizando actividades de forma eficiente que aporten al mejoramiento económico, productivo y social del país.",
          PerfilOcupacional:"El ingeniero de Software de la Fundación Universitaria Horizonte estará capacitado para desempeñarse en organizaciones públicas o privadas de los diferentes sectores económicos como: •	Director de sistemas informáticos. •	Director de desarrollo de aplicaciones y TIC. •	Director de tecnología de la información. •	Arquitecto de soluciones de negocios. •	Diseñador de sistemas de las TIC. •	Desarrollador de software •	Desarrollador web y multimedia. •	Programador de aplicaciones.",
          Denominación: "Ingeniería de Software",
          Titulo: "Ingeniero de software",
          Modalidad: "Presencial",
          Creditos:"154 Créditos",
          Semestres:"9",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "107406",
          Registro: "013534",
          Matricula: "$6.160.050",
          planEstudio: planeIngSoftw,
          videoFondo: panta,
          Video: "https://www.youtube.com/embed/UVPjveApgD8",
          Facultad:"Facultad de Ingenierías",
          Telefono:"Tel: 7437270 Ext. 129",
          Email: "ingenieriadesoftware@unihorizonte.edu.co",
          certificacion: "Certificado en Lenguajes de Programación",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${banneringsoftware})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoingsoftware.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoingsoftware.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default IngSoftware