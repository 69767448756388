import React from 'react';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../img/logo.png";
import dian from "../img/logoDian.png";
import { Link } from "react-router-dom";

const Header = () => {

  const ScrollToTop = () => {
    window.scrollTo({
      top: (0,0),
      behavior: 'smooth',
    });

  return null;
}

  const [isMobileNavActive, setIsMobileNavActive] = useState(false); 

  useEffect(() => {
    if (isMobileNavActive) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }, [isMobileNavActive]);

  const toggleMobileNav = () => {
    setIsMobileNavActive(!isMobileNavActive);
  };

  const toggleDropdown = (event) => {
    if (isMobileNavActive) {
      event.preventDefault();
      event.stopPropagation(); // Detener la propagación del evento
  
      const targetElement = event.target;
      const nextElement = targetElement.nextElementSibling;
      const dropDownIndicator = targetElement.querySelector('.dropdown-indicator');
  
      if (targetElement && nextElement && dropDownIndicator) {
        targetElement.classList.toggle('active');
        nextElement.classList.toggle('dropdown-active');
        dropDownIndicator.classList.toggle('bi-chevron-up');
        dropDownIndicator.classList.toggle('bi-chevron-down');
      }
    }
  };

  const handleNavlinkClick = () => {
    if (isMobileNavActive) {
      // Cerrar la navegación móvil cuando se hace clic en un enlace
      closeMobileNav();
    }
  };

  const closeMobileNav = () => {
    setIsMobileNavActive(false);
  };

    return (

      <header id="header" className= "header d-flex align-items-center justify-content-between">
         <div className="container-fluid d-flex align-items-center  ">

      <Link to="/" className="logo d-flex align-items-center" onClick={ScrollToTop}>
        <img src={logo} alt="Logo Unihorizonte"/> 
        
      </Link>

      <i className={`mobile-nav-toggle ${isMobileNavActive ? 'mobile-nav-hide' : 'mobile-nav-show'}  bi bi-list`} onClick={toggleMobileNav}></i>
      <i  className={`mobile-nav-toggle ${isMobileNavActive ? 'mobile-nav-show' : 'mobile-nav-hide'} d-none  bi bi-x`} onClick={closeMobileNav}></i>
      <nav id="nav_bar" className="nav_bar"> 
       <div>
         <a 
          className="btn" 
          href="/Dian" 
          target="_blank" 
          alt="Botón" 
          style={{ 
          display: "inline-block", 
          padding: "1px 5px", 
          backgroundColor: "#F1A218", 
          color: "#FFF", 
          textDecoration: "none", 
          borderRadius: "8px" 
          }}
          >
          DIAN Actualización RTE.
          </a>
        </div>

        <ul>
          <li className="dropdown"onClick={toggleDropdown}><Link to="#" onClick={ScrollToTop} ><span>Institucional</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
            <ul>
              <li className="dropdown"onClick={toggleDropdown} ><Link to="#"onClick={ScrollToTop}><span>Nuestra Institución</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li onClick={handleNavlinkClick}><Link to="/QuienesSomos"onClick={ScrollToTop} >Quienes Somos</Link></li>
                </ul>
              </li>
              <li  onClick={handleNavlinkClick}><Link to="/Documentos" onClick={ScrollToTop}><span>Documentos</span> </Link></li>             
            </ul>
          </li>

          <li className="dropdown" onClick={toggleDropdown} ><Link to="#"onClick={ScrollToTop}><span>Programas</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
            <ul>
              <li className="dropdown" onClick={toggleDropdown}><Link to="#"onClick={ScrollToTop}><span>Facultades</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
                <ul>
                  <li onClick={handleNavlinkClick}><Link to="/facultades/facultadIngenieria" onClick={ScrollToTop}>Facultad de Ingeniería</Link></li>
                  <li onClick={handleNavlinkClick}><Link to="/facultades/facultadCienciasAdministrativas"onClick={ScrollToTop}>Facultad de Ciencias Administrativas</Link></li>
                  <li onClick={handleNavlinkClick}><Link to="/facultades/facultadComunicacion"onClick={ScrollToTop}>Facultad de Comunicación, Arte y Marketing Digital</Link></li>
                  <li onClick={handleNavlinkClick}><Link to="/facultades/facultadGastronomia"onClick={ScrollToTop}>Facultad de Gastronomía</Link></li>
                  <li onClick={handleNavlinkClick}><Link to="/facultades/facultadCienciasJuridicas"onClick={ScrollToTop}>Facultad de Ciencias Jurídicas</Link></li>
                </ul>
              </li>
              <li onClick={handleNavlinkClick}><Link to="/posgrados" onClick={ScrollToTop}>Posgrados</Link></li>

              <li className="dropdown" onClick={toggleDropdown} ><Link to=""><span>Educación Continuada</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
              
                <ul>
                  <li onClick={ScrollToTop}><Link to="/educacionContinua" onClick={handleNavlinkClick}>Diplomados - Cursos</Link></li>
                  <li><a href="https://unihorizonte.edu.co/certificados/"onClick={handleNavlinkClick} target='_blank'>Certificados de Educación Continua</a></li>
                </ul>
              </li>              
            </ul>
          </li>

          <li className="dropdown" onClick={toggleDropdown}><Link to="#" onClick={ScrollToTop}><span>Admisiones</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
            <ul>
              <li onClick={handleNavlinkClick}><Link to="/Proceso_de_inscripcion" onClick={ScrollToTop}>Proceso de Inscripción</Link></li>
              <li onClick={handleNavlinkClick}><Link to="/Financiamiento" onClick={ScrollToTop}>Financiamento</Link></li>
            </ul>
          </li>

          <li className="dropdown" onClick={toggleDropdown}><Link to="#" onClick={ScrollToTop}><span>Proyección Social</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
            <ul>
              <li onClick={handleNavlinkClick}><Link to="/Egresados" onClick={ScrollToTop} >Egresados</Link></li>
              <li onClick={handleNavlinkClick}><Link to="/Practicas" onClick={ScrollToTop} >Prácticas</Link></li>
              <li onClick={handleNavlinkClick}><Link to="/Servicio_social" onClick={ScrollToTop} >Servicio social</Link></li>
              <li onClick={handleNavlinkClick}><Link to="/Internacionalizacion" onClick={ScrollToTop} >Internacionalización</Link></li>
            </ul>
          </li>

          <li onClick={handleNavlinkClick}><Link to="/investigacion"onClick={ScrollToTop}><span>Investigación</span></Link></li>

          <li className="dropdown" onClick={toggleDropdown}><Link to="#"><span>Horizontista</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
            <ul>
              <li onClick={handleNavlinkClick}><Link to="/Bienestar"onClick={ScrollToTop}>Bienestar Universitario</Link></li>
              <li onClick={handleNavlinkClick}><Link to="/Biblioteca"onClick={ScrollToTop}>Biblioteca</Link></li>
              {/* <li onClick={handleNavlinkClick}><a href="http://biblioteca.unihorizonte.edu.co:8081/" onClick={toggleDropdown} target='_blank'>Biblioteca</a></li> */}
              <li onClick={handleNavlinkClick}><a href="https://FUH.q10.com/SolicitudesInstitucionales/NuevaSolicitud" onClick={toggleDropdown} target='_blank'>Solicitudes Institucionales</a></li>
              <li onClick={handleNavlinkClick}><a href="https://FUH.q10.com/SolicitudesInstitucionales/ConsultarSolicitud" onClick={toggleDropdown} target='_blank'>Consultar solicitud</a></li>
              <li onClick={handleNavlinkClick}><Link to="/Horizontista/blog"onClick={toggleDropdown & ScrollToTop}>Blog</Link></li>
            </ul>
          </li>

          {/* <li onClick={handleNavlinkClick} ><a href="https://eduvirtual.unihorizonte.edu.co/" target="_blank" >Campus Virtual</a></li> */}

          <li className="dropdown" onClick={toggleDropdown}><Link to="#" onClick={ScrollToTop}><span>Campus Virtual</span> <i className="bi bi-chevron-down dropdown-indicator"></i></Link>
         
          <ul>
              <li onClick={handleNavlinkClick}><a href="https://eduvirtual.unihorizonte.edu.co/" onClick={toggleDropdown} target='_blank'>Campus Virtual</a></li>
              <li onClick={handleNavlinkClick}><Link to="/ContactoCJ"onClick={ScrollToTop}>Consultorio Jurídico Virtual</Link></li>
          </ul>
          </li>


          <li onClick={handleNavlinkClick} ><Link to="https://fuh.q10.com/" target="_blank">Sistema Académico</Link></li>
          
          <li onClick={handleNavlinkClick}><Link to="/innovacion"onClick={ScrollToTop}><span>Escuela de Innovación y Emprendimiento</span></Link></li>

        </ul>
      </nav>

    </div>
  </header>
      

      
  
     

        
    )
}

export default Header