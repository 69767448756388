import React from "react";
import bannerContaduria from '../../img/facultades/contaduria.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_contaduria_publica.png';
import planeContaduria from '../../img/Plan-estudios/contaduria-publica.png';





const Contaduria =() => {

    const infoContaduria = [
        {
          Ficha: "El programa de Contaduría Pública de la Fundación Universitaria Horizonte forma profesionales integrales con sólidos conocimientos en contabilidad y finanzas, con principios éticos y responsabilidad social para que sean ciudadanos comprometidos con la sociedad y su entorno, generando valor agregado en las organizaciones, de acuerdo con las normas nacionales e internacionales, con habilidades para analizar información financiera e identificar tendencias para evaluar riesgos y tomar decisiones estratégicas basadas en datos.",
          Perfil:"El profesional en Contaduría Pública combina habilidades técnicas con visión estratégica, habilidades financieras y éticas, preparándolos para enfrentar los desafíos del mundo empresarial y generar un impacto significativo en las organizaciones y la sociedad, lo que les permite influir positivamente en las decisiones organizacionales y contribuir al éxito empresarial, identifica oportunidades de mejora en las finanzas de una organización y proponer soluciones creativas para optimizar recursos y maximizar beneficios.",
          PerfilOcupacional:"El Contador Público de la Fundación Universitaria Horizonte podrá desempeñarse como: •	Gerente financiero •	Auditor •	Revisor fiscal •	Director contable •	Gerente de planeación •	Consultor •	Director de Nomina •	Director del Departamento de Contabilidad.",
          Denominación: "Contaduría Pública",
          Titulo: "Contador Público",
          Modalidad: "Presencial",
          Creditos:"157 Créditos",
          Semestres:"9",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "107725",
          Registro: "1048",
          Matricula: "$4.384.550",
          planEstudio: planeContaduria,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2STOl2OKTW/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Con certificación en Marketing para Contadores",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerContaduria})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoContaduria.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoContaduria.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default Contaduria