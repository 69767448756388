import React from "react";
import '../css/investigacion.css';
import bannercadmon from '../../src/img/investigacion/banner.jpg';
import revista1 from '../img/investigacion/revista_stylus/vol3.png';
import revista2 from '../img/investigacion/revista_teckne/vol17.JPG';
import grupoinvnanshe from '../img/investigacion/logo-nanshe.png';
import logopia from '../img/investigacion/logo_pias.png';
import documpia from '../../src/documentos/investigacion/formato_PIA_2023_VF.docx';
import posterpia from '../../src/documentos/investigacion/POSTER-PIAS-2024-1.pptx';
import diligenciarpia from '../../src/documentos/investigacion/Instructivo_diligenciamiento_poster.pdf';
import docuproyinvesti from '../../src/documentos/investigacion/4_F-165 PROYECTO INVESTIGACION_VF.docx';
import docuanteproyecto from '../../src/documentos/investigacion/3_F-051 ANTEPROYECTO_VF.doc';
import prueba from '../img/investigacion/inves1.jpg';
import prueba1 from '../img/investigacion/invest2.jpg';
import prueba2 from '../img/investigacion/galericopy.jpg';
import prueba4 from '../img/investigacion/galeri3.jpeg';
import prueba5 from '../img/investigacion/galeri6.jpg';
import prueba6 from '../img/investigacion/galeri_copy.jpg';
import prueba7 from '../img/investigacion/invest8.jpg';
import logoImg from '../img/investigacion/logo_gcena.svg';
import logoImg1 from '../img/investigacion/logo-sisom.svg';
import logoImg4 from '../img/investigacion/logo-gastro.svg';
import logoImg5 from '../img/investigacion/logo_vertice.svg';
import logoImg6 from '../img/investigacion/logo-tech.svg';
import logoImg7 from '../img/investigacion/logo_dione.svg';
import info1 from '../img/investigacion/infografia_proyecto_de_grado.jpg';
import info2 from '../img/investigacion/infografia-01.jpg';
import info3 from '../img/investigacion/infografia-02.jpg';
import { CardInvestigacion } from '../componentes/investigacion/CardInvestigacion';
import { CardSemillero } from '../componentes/investigacion/CardSemillero';
import videoDiana from '../img/investigacion/Diana_Ramírez.mp4';
import videoAda from '../img/investigacion/Ada.mp4';
import videoEfren from '../img/investigacion/Efren.mp4';
import { GrDocumentDownload } from "react-icons/gr";
import { GiVideoConference } from "react-icons/gi"
import { Galeria } from "../componentes/investigacion/Gallery";
import { Link } from "react-router-dom";
// import { galeriaVideo } from "../componentes/investigacion/galeriaVideo";
import { GaleriaVideo } from "../componentes/investigacion/galeriaVideo";


const Investigación = () => {

  const ScrollToTop = () => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth",
    });
    return null
  }
  /*const Cardsemillero = [
    {
      title: "Grupo de investigación ",
      imgUrl: logoImg6,
      imgback: prueba1,
      depto: "Facultad de ingeniería –Ingeniería en Seguridad Industrial e Higiene Ocupacional",
      lider: "Edwin Castellanos Gomez",
      email: "dirdesarrollo@unihorizonte.edu.co",
    },
    {
      title: "Grupo de investigación ",
      imgUrl: logoImg6,
      imgback: prueba1,
      depto: "Facultad de ingeniería –Ingeniería en Seguridad Industrial e Higiene Ocupacional",
      lider: "Edwin Castellanos Gomez",
      email: "dirdesarrollo@unihorizonte.edu.co",
    },
    {
      title: "Grupo de investigación ",
      imgUrl: logoImg6,
      imgback: prueba1,
      depto: "Facultad de ingeniería –Ingeniería en Seguridad Industrial e Higiene Ocupacional",
      lider: "Edwin Castellanos Gomez",
      email: "dirdesarrollo@unihorizonte.edu.co",
    },
    {
      title: "Grupo de investigación ",
      imgUrl: logoImg6,
      imgback: prueba1,
      depto: "Facultad de ingeniería –Ingeniería en Seguridad Industrial e Higiene Ocupacional",
      lider: "Edwin Castellanos Gomez",
      email: "dirdesarrollo@unihorizonte.edu.co",
    },

              <div className="row gy-5">
            <div className="col-lg-12 col-md-12 text-center align-items-center">
              <h2 className="title-grupos">Nuestros Grupos de Semilleros</h2>
              <p>Espacios extracurriculares conformados de manera voluntaria por estudiantes y docentes de los programas académicos de la institución, tienen como objetivo principal promover la cultura investigativa en el entorno académico y promover el desarrollo de la ruta de investigación institucional, contribuyendo así al avance del conocimiento y a la solución de problemáticas relacionadas con las diferentes disciplinas y áreas del saber.</p>
            </div>
            {
              Cardsemillero.map((container, index) => {
                return (
                  <CardSemillero
                    key={index}
                    {...container}
                  />
                )
              })
            }
          </div>
  ]*/

  const Cardinvestigacion = [
    {
      title: "Grupo de investigación en Seguridad Industrial, Salud Ocupacional y Medio Ambiente",
      imgUrl: logoImg1,
      imgback: prueba1,
      categoria: "COL0107136- Categoría C",
      depto: "Facultad de ingeniería –Ingeniería en Seguridad Industrial e Higiene Ocupacional y Técnico Profesional en Seguridad y Salud en el trabajo",
      lider: "Líder(es): Diana Tamayo-Zuleima Barreto",
      email: "dirinvestigaciones@unihorizonte.edu.co",
    },
    {
      title: "Grupo de Investigación en Enseñanza de las Ciencias Exactas y Naturales Aplicadas",
      imgUrl: logoImg,
      imgback: prueba6,
      categoria: "COL0199192-Categoría C",
      depto: "Facultad de ingeniería",
      lider: "Líder(es): Luisa García-Diana Tamayo",
      email: "garcia_luisa@unihorizonte.edu.co",
    },
    {
      title: "Grupo de Investigación DIONE",
      imgUrl: logoImg7,
      imgback: prueba,
      categoria: "COL0202304",
      depto: "Facultad de Ciencias Administrativas",
      lider: "Líder: Efrén Antonio Calderón",
      email: "efren_calderon20212@unihorizonte.edu.co",
    },
    {
      title: "Grupo de Investigación Horizonte Cultural Gastronómico",
      imgUrl: logoImg4,
      imgback: prueba4,
      categoria: "COL0231754",
      depto: "Facultad de Gastronomía",
      lider: "Líder: Edy Mayerly González",
      email: "direccion.gastronomia@unihorizonte.edu.co",
    },
    {
      title: "Grupo de Investigación Vértice",
      imgUrl: logoImg5,
      imgback: prueba5,
      categoria: "COL0232223",
      depto: "Facultad de Comunicación y Marketing Digital-Diseño gráfico, comunicación y mercadeo",
      lider: "Líder: Martha Cristina Ramírez",
      email: "direccion.comunicacion@unihorizonte.edu.co",
    },
    {
      title: "Grupo de Investigación TECH-INNOVA",
      imgUrl: logoImg6,
      imgback: prueba2,
      categoria: "COL0229658",
      depto: "Facultad de ingeniería – ingeniería de Software",
      lider: "Líder: Diana Patricia Camargo",
      email: "rectoria@unihorizonte.edu.co",
    },
    {
      title: "Grupo de Investigación NANSHE",
      imgUrl: grupoinvnanshe,
      imgback: prueba7,
      categoria: "COL0233982",
      depto: "Facultad de Ciencias Jurídicas",
      lider: "Líder: Jayme Baez",
      email: "direccion.derecho@unihorizonte.edu.co",
    },
  ];
  return (
    <>
      <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: `url(${bannercadmon})` }}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
      </div>
      <section id="investigacion" className="investigacion">
        <div className="container" data-aos="fade-up">
          <div className="col-lg-12 col-md-12 text-center">
            <h2>Investigación</h2>
            <p className="descripcion text-justify">
              En la Fundación Universitaria Horizonte la investigación se constituye como fuente de saber, generadora y soporte del ejercicio docente, que como propósito principal tiene la producción y validación de nuevo conocimiento para impulsar el desarrollo académico, científico, tecnológico, cultural y social del país. Para lograr este fin, el Proyecto Educativo Institucional (PEI) de la fundación Universitaria Horizonte propone desde la óptica de la investigación, “una universidad comprometida con la cultura investigativa, el pensamiento científico y tecnológico” a través de un modelo pedagógico de desarrollo humano, que está orientado a “visibilizar el conocimiento y los actores que se encuentran tras las huellas de este.”
            </p>
          </div>
          <div className="container d-flex">
            <div className="col-lg-6 col-sm-12" data-aos="zoom-in" data-aos-delay="200">
              <img src={info1} className="img-fluid revistas" alt="" />
              <div className="pia-descarga ">
                <div className="pia-descarga-item d-flex justify-content-center">
                  <a href={docuproyinvesti}><i><GrDocumentDownload /></i>
                    <p>formato proyecto de investigación</p></a>
                  <a href={docuanteproyecto}><i><GrDocumentDownload /></i>
                    <p>formato de anteproyecto</p></a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12" data-aos="zoom-in" data-aos-delay="200">
              <img src={info3} className="img-fluid revistas" alt="" />
            </div>
          </div>
          <div className="row gy-5">
            <div className="col-lg-12 col-md-12 text-center align-items-center">
              <h2 className="title-grupos">Nuestros Grupos de investigación</h2>
            </div>
            {
              Cardinvestigacion.map((container, index) => {
                return (
                  <CardInvestigacion
                    key={index}
                    {...container}
                  />
                )
              })
            }
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="video-container">
                <video loop controls id='videoInvestigacion' >
                  <source src={videoDiana} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="video-container">
                <video loop controls id='videoInvestigacion' >
                  <source src={videoAda} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="video-container">
                <video loop controls id='videoInvestigacion' >
                  <source src={videoEfren} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          {/* <div className="container galeria_investigacion">
                <div className="col-xl-12 col-lg-12" data-aos="zoom-in" data-aos-delay="200">
                  <GaleriaVideo/>
                </div>
              </div> */}
          <div className="container" data-aos="fade-up">
            <div className="col-lg-12 col-md-12 text-center align-items-center">
              <h2 className="title-grupos">Divulgación de la investigación</h2>
            </div>
          </div>
          <div className="container d-flex" style={{ textAlign: "center" }}>
            <div className="col-lg-6 col-sm-12" data-aos="zoom-in" data-aos-delay="200">
              <h3 className="text-center">Enlace a Stylus</h3>
              <Link to='/investigacion/stylus' onClick={ScrollToTop} ><img src={revista1} className="img-fluid revistas" alt="" /></Link>
            </div>
            <div className="col-lg-6 col-sm-12" data-aos="zoom-in" data-aos-delay="200">
              <h3 className="text-center ">Enlace a TECKNE</h3>
              <Link to='/investigacion/teckne' onClick={ScrollToTop}><img src={revista2} className="img-fluid revistas" alt="" /></Link>
            </div>
          </div>
          <div className="container" data-aos="fade-up">
            <div className="col-lg-12 col-md-12 text-center align-items-center">
              <h2 className="title-grupos">Nuestros eventos</h2>
            </div>
          </div>
          <div className="container galeria_investigacion">
            <div className="col-xl-12 col-md-12" data-aos="zoom-in" data-aos-delay="200">
              <Galeria />
            </div>
          </div>
        </div>
        <div className=" bg-section pias" >
          <div className="col-lg-12 col-md-12 text-center align-items-center justify-content-center">
            <h2 className="title-pias">Proyectos Integrados de Aula</h2>
            <img src={logopia} alt="logo_pia" className="logo_pias" />
          </div>
          <div className="container">
            <div className="col-sm-12 pias-item" data-aos="zoom-in" data-aos-delay="200">
              <p className="text-pias">Proyectos de investigación formativa elaborados bajo el Aprendizaje Basado en Proyectos como trabajos educativos prolongados que se realizan a lo largo del semestre, en los cuales se integran conocimientos y conceptos de diferentes asignaturas para su construcción. Estos se socializan y evalúan durante la semana Horizontista.</p>
            </div>
            <div className="col-sm-12 pias-item" data-aos="zoom-in" data-aos-delay="200">
              <table class="col-lg-10 col-md-12 mx-auto table">
                <thead>
                  <tr>
                    <th scope="col" className="item-table text-center">ACTIVIDAD</th>
                    <th scope="col" className="item-table text-center">RESPONSABLE</th>
                    <th scope="col" className="item-table text-center">COMPROMISO</th>
                    <th scope="col" className="item-table text-center">FECHA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Asignación PIAS</td>
                    <td>Direcciones de programa</td>
                    <td>Asignación de docentes y asignaturas por semestre </td>
                    <td className="date">01 Agosto 2024</td>
                  </tr>
                  <tr>
                    <td>Socialización PIAS y asignación PIAS docentes</td>
                    <td>Investigaciones y direcciones de programa</td>
                    <td></td>
                    <td className="date">05 y 06 Agosto 2024
                    (Bienvenida docente)</td>
                  </tr>
                  <tr>
                    <td>Solución de dudas y retroalimentación PIA</td>
                    <td>Dirección de investigaciones y direcciones de programa</td>
                    <td>Reunión con docentes de cada programa</td>
                    <td className="date">12-17 Agosto 2024</td>
                  </tr>
                  <tr>
                    <td>Planteamiento PIAS - Caso Estudio</td>
                    <td>Docentes encargados</td>
                    <td>Tema seleccionado por el grupo de docentes o planeación del PIA.
                    Acta de reunión</td>
                    <td className="date">19—24 Agosto 2024</td>
                  </tr>
                  <tr>
                    <td>Desarrollo de PIAS</td>
                    <td>Estudiantes y docentes encargados</td>
                    <td>Diligenciamiento formato proyecto investigación formativa</td>
                    <td className="date">Semestre académico</td>
                  </tr>
                  <tr>
                    <td>Sesiones de asesoría</td>
                    <td>Investigaciones y docentes asignados programas</td>
                    <td>Acompañamiento sincrónico Teams</td>
                    <td className="date">7 Octubre -26 Octubre 2024</td>
                  </tr>
                  <tr>
                    <td>Inscripción encuentro</td>
                    <td>Estudiantes y docentes encargados</td>
                    <td>Inscripción Encuentro PIAS forms</td>
                    <td className="date">7-12 Octubre 2024</td>
                  </tr>
                  <tr>
                    <td>Entrega de PIAS seleccionados</td>
                    <td>Estudiantes y docentes encargados</td>
                    <td>Documento en el formato, poster, ppt</td>
                    <td className="date">21-26 Octubre 2024</td>
                  </tr>
                  <tr>
                    <td>Presentación PIAS</td>
                    <td>Direcciones de programa/Investigaciones Docentes inv</td>
                    <td>Presentación de posters y ponencias</td>
                    <td className="date">28 Octubre al 02 Noviembre 2024</td>
                  </tr>

                  <tr>
                    <td>Premiación PIAS</td>
                    <td>Dirección de investigaciones</td>
                    <td></td>
                    <td className="date">11-16 Noviembre 2024</td>
                  </tr>

                  <tr>
                    <td>Entrega soportes y base para certificados</td>
                    <td>Docente líder evento PIASs</td>
                    <td>- Entrega informe del evento
- Entrega BPMN0002
- Entrega base de datos evento</td>
                    <td className="date">18-23 Noviembre 2024</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
          <div className=" container col-lg-12 col-sm-12 align-items-center justify-content-center">
            <div className="pia-descarga ">
              <div className="pia-descarga-item d-flex justify-content-center ">
                <a href={documpia}><i><GrDocumentDownload /></i>
                  <p>Archivo PIA</p></a>
                <a href={posterpia}><i><GrDocumentDownload /></i>
                  <p>Instructivo diligenciamiento poster</p></a>
                  <a href={diligenciarpia}><i><GrDocumentDownload /></i>
                  <p>Poster PIA</p></a>
              </div>
              <div className="pia-descarga-item d-flex justify-content-center">
                <a href="https://outlook.office365.com/owa/calendar/AsesoraspostersPIAS@unihorizonte.edu.co/bookings/" target="_blank"><i><GiVideoConference /></i>
                  <p>Sesiones de acompañamiento</p></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Investigación