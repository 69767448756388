import React from "react";

import bannerderecho from '../../img/facultades/derecho.jpg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica";
import panta from '../../img/cursos/panta-derecho.png'
import planeDerecho from '../../img/Plan-estudios/Derecho.png'




const Gastronomia =() => {

    const infoGastro = [
        {
          Ficha: "Descubre el camino hacia una carrera legal excepcional con nuestro programa de derecho en la FUNDACION UNIVERSITARIA HORIZONTE. En este programa, te ofrecemos una educación de calidad que combina fundamentos legales sólidos con enfoques innovadores de aprendizaje. Nuestro compromiso es equiparte con las habilidades y el conocimiento necesarios para destacar en el mundo legal moderno. A través de una facultad apasionada y experta, obtendrás una comprensión integral de las leyes y su aplicación en un contexto global. Nuestro programa se destaca por fomentar el pensamiento crítico, la ética y la excelencia. Únete a nosotros para ser pionero en la creación de un legado legal significativo mientras moldeamos juntos el futuro de la justicia y el derecho.",
          Perfil:"El egresado del programa de Derecho en la Fundación Universitaria Horizonte es un Abogado altamente capacitado. •	Enfrenta los retos legales que platea la era digital. •	Su formación integral le permite abordar la intersección entre el derecho de las nuevas tecnologías de manera crítica y responsable. •	Está preparado para liderar en un entorno legal en constante cambio. •	Su capacidad para combinar conocimientos jurídicos lo convierte en un profesional esencial para la protección de derechos.",
          PerfilOcupacional:"El egresado del programa de Derecho en la Fundación Universitaria Horizonte se prepara para desempeñarse como: •	Abogado litigante. •	Juez. •	Fiscal. •	Magistrado. •	Notario. •	Asesor. •	Consultor. •	Docente. •	Diplomático. •	Ministro. •	Emprendedor.",
          Denominación: "Derecho",
          Titulo: "Abogado",
          Modalidad: "Presencial",
          Creditos:"164 Créditos",
          Semestres:"10",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "107213",
          Registro: "12019",
          Matricula: "$5.238.400",
          planEstudio: planeDerecho,
          videoFondo: panta,
          Video: "https://www.youtube.com/embed/PAV8GQxUm6s",
          Facultad:"Facultad de Ciencias Jurídicas",
          Telefono:"Tel: 7437270 Ext. 145",
          Email: "direccion.derecho@unihorizonte.edu.co",
          certificacion: "Certificación en Tecnología e IA",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerderecho})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoGastro.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoGastro.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default Gastronomia