import React from "react";
import '../css/innovacion.css';
import bannercadmon from '../../src/img/innovacion/banner_innovacion.jpg';
import asesoria from '../img/innovacion/asesoria_emprendedores.jpg';
import formacion from '../img/innovacion/formacion_continua.jpg';
import acompañamiento from '../img/innovacion/acompañamiento_de_mentores.jpg';
import videos from '../img/innovacion/videos_de_apoyo.jpg';
import { CardInnovacion } from '../componentes/investigacion/CardInnovacion';



const Investigación = () => {

  const ScrollToTop = () => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth",
    });
    return null
  }

  const Cardinnovacion = [
    {
      title: "Asesoría Emprendedores",
      imgback: asesoria,
      texto: "Asesorar a emprendedores en etapa de pre-incubación, incubación o aceleración mediante mentores con alto profesionalismo demostrado en su conocimiento y/o experiencia, estas asesorías pueden ser virtuales o presenciales.",
      boton: "Conoce más"

    },
    {
      title: "Formación Continua para Emprendedores",
      imgback: formacion,
      texto: "Se cuenta con capsulas de formación donde de manera corta cada emprendedor puede conocer o profundizar un tema de interés según en la etapa que se encuentre.",
      boton: "Conoce más"

    },
    {
      title: "Acompañamiento de Mentores",
      imgback: acompañamiento,
      texto: "Los emprendedores tendrán la oportunidad de contar con mentores internos o externos que los acompañen en su proceso, estos mentores son Empresarios, así como profesionales de las diferentes áreas de conocimiento.",
      boton: "Conoce más"

    },
    {
      title: "Vídeos de apoyo",
      imgback: videos,
      texto: "Los emprendedores pueden ver la serie de Tecnología, Innovación y Emprendimiento trabajada por la Institución donde encontrarán información de interés y casos de éxito que servirán de inspiración.",
      boton: "Conoce más"

    },


  ];
  return (
    <>
      <div className="breadcrumbs" style={{ backgroundImage: `url(${bannercadmon})` }}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
      </div>
      <section id="innovacion" className="innovacion">
        <div className="container" data-aos="fade-up">
          <div className="row gy-5">
            <div className="col-lg-12 col-md-12 text-center align-items-center">
              <h2 className="title-grupos">Servicios Escuela de Innovación y Emprendimiento</h2>
            </div>
            {
              Cardinnovacion.map((container, index) => {
                return (
                  <CardInnovacion
                    key={index}
                    {...container}
                  />
                )
              })
            }
          </div>
        </div>
        <div className="informacion">
          <div className="seccion text-center align-items-center">
              <h1>Solicita información</h1>
              <a className="btn-info" href="/contactenos">Información</a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Investigación