import React from "react";
import bannerCalidad from '../../../img/facultades/calidad.jpg';
import Pasos from "../../../componentes/programas/Pasos";
import {Contacto} from "../../../componentes/programas/Contacto";
import {Fichatecnica} from "../../../componentes/programas/FichaTecnica";
import gastrocurse from '../../../img/cursos/gastro-course.jpg';
import planeEspGCalidad from '../../../img/Plan-estudios/esp-gerencia-calidad.png';





const EspGerenCalidad =() => {

    const infoEspGerenCalidad = [
        {
          Ficha: "El Especialista en Gerencia de la Calidad de la Fundación Universitaria Horizonte, brinda las herramientas necesarias para impulsar la excelencia operativa en las organizaciones, para aumentar la satisfacción del cliente y mejorar la competitividad en el mercado, con el conocimiento de las normas y estándares de calidad.",
          Perfil:"La Especialización en Gerencia de la Calidad de la Fundación Universitaria Horizonte está diseñada para formar profesionales capaces de liderar y gestionar sistemas de calidad, con competencias para diseñar, implementar y evaluar sistemas de gestión, promoviendo la mejora continua y la competitividad en las organizaciones.",
          PerfilOcupacional:"La Especialización en Gerencia de la Calidad está dirigida a una amplia gama de profesionales que buscan mejorar sus habilidades en la gestión de la calidad y la implementación de sistemas de gestión. Personal encargado de la implementación y mantenimiento de sistemas de gestión de calidad en las organizaciones, que brindan asesoría en la gestión de calidad y buscan actualizar sus conocimientos y técnicas.",
          Denominación: "Especialización en Gerencia de la Calidad",
          Titulo: "Especialista en gerencia de la calidad",
          Modalidad: "Presencial",
          Creditos:"34 Créditos",
          Semestres:"2",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109576",
          Registro: "19915",
          Matricula: "$8.575.250",
          planEstudio: planeEspGCalidad,
          videoFondo: gastrocurse,
          Video: "https://www.youtube.com/embed/LXb3EKWsInQ",
          Facultad:"Facultad de Posgrados",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Con Certificación en Procesos y procedimientos",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerCalidad})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoEspGerenCalidad.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento. <br/> Descuentos especiales.</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoEspGerenCalidad.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default EspGerenCalidad