import React from "react";
import bannertectalentohumano from '../../img/facultades/talento_humano.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_tec_talento_humano.png';
import planeTecGestiontalHumano from '../../img/Plan-estudios/tecnologia-gestion-talento-humano.png';

const TecGestionTalentoHumano =() => {

    const infoTecGestionTalentoHumano  = [
        {
          Ficha: "La Tecnología en Gestión del Talento Humano de la Fundación Universitaria Horizonte forma profesionales con un alto sentido ético y de responsabilidad social, comprometidos con la transparencia y la sostenibilidad, promoviendo prácticas empresariales justas y equitativas, implementando estrategias de selección, vinculación, bienestar, desarrollo y evaluación del talento humano, contribuyendo al crecimiento y satisfacción de los empleados.",
          Perfil:"El Tecnólogo en Gestión del Talento Humano está preparado para utilizar plataformas virtuales y herramientas tecnológicas avanzadas en la gestión del talento humano. Su capacidad para manejar sistemas de información y software especializado le permite optimizar los procesos administrativos y de recursos humanos, actúa con un alto sentido ético y de responsabilidad social, promoviendo prácticas empresariales justas y equitativas, su enfoque está orientado al bienestar de la comunidad y al desarrollo sostenible.",
          PerfilOcupacional:"El Tecnólogo en Gestión del Talento Humano puede desempeñarse como: •	Auxiliar o Asistente de nómina •	Auxiliar o Asistente de reclutamiento •	Auxiliar o Asistente de Recursos Humanos •	Auxiliar o Asistente de capacitación del personal •	Analista y evaluador del desempeño.",
          Denominación: "Tecnología en Gestión del talento humano",
          Titulo: "Tecnólogo/a en gestión del talento humano",
          Modalidad: "Presencial",
          Creditos:"103 Créditos",
          Semestres:"6",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109382",
          Registro: "8224",
          Matricula: "$2.902.350",
          planEstudio: planeTecGestiontalHumano,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2LipX3P2kt/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracion@unihorzionte.edu.co",
          certificacion: "Certificación en Planes de Capacitación",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannertectalentohumano})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoTecGestionTalentoHumano .map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoTecGestionTalentoHumano .map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default TecGestionTalentoHumano