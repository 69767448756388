import React from "react";
import bannerproyectos from '../../../img/facultades/ger_proyectos.jpg';
import Pasos from "../../../componentes/programas/Pasos";
import {Contacto} from "../../../componentes/programas/Contacto";
import {Fichatecnica} from "../../../componentes/programas/FichaTecnica";
import gastrocurse from '../../../img/cursos/gastro-course.jpg';
import planeEspGProyectos from '../../../img/Plan-estudios/esp-gerencia-proyectos.png';





const EspGerenciaProyectos =() => {

    const infoEspGerenciaProyectos = [
        {
          Ficha: "El Especialista en Gerencia de Proyectos de la Fundación Universitaria Horizonte contará con competencias en todas las fases de la gestión de proyectos, desde la concepción hasta la finalización, asegurando una visión estratégica ayudando a las organizaciones a mejorar sus procesos y resultados, con habilidad para liderar equipos multidisciplinarios y gestionar el talento humano para alcanzar los objetivos del proyecto.",
          Perfil:"La Especialización en Gerencia de Proyectos forma profesionales altamente capacitados en la gestión integral de proyectos, proporcionando las herramientas y conocimientos necesarios para planificar, ejecutar, dirigir, evaluar y controlar proyectos en diversos campos y disciplinas, utilizando recursos físicos, técnicos, tecnológicos y financieros de manera eficiente.",
          PerfilOcupacional:"La Especialización en Gerencia de Proyectos de la Fundación Universitaria Horizonte está dirigida a una amplia variedad de profesionales que buscan mejorar sus habilidades en la gestión de proyectos, que desean adquirir competencias en la gestión de proyectos para mejorar la eficiencia y efectividad de sus proyectos. Este programa es ideal para cualquier profesional que quiera adquirir conocimientos avanzados en la gestión de proyectos y aplicar estos principios para mejorar la eficiencia y competitividad de sus organizaciones.",
          Denominación: "Especialización en Gerencia de Proyectos",
          Titulo: "Especialista en Gerencia de Proyectos",
          Modalidad: "Presencial",
          Creditos:"32 Créditos",
          Semestres:"2",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109384",
          Registro: "9887",
          Matricula: "$8.575.250",
          planEstudio: planeEspGProyectos,
          videoFondo: gastrocurse,
          Video: "https://www.youtube.com/embed/LXb3EKWsInQ",
          Facultad:"Facultad de Posgrados",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Certificación en Retorno de la Inversión",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerproyectos})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoEspGerenciaProyectos.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoEspGerenciaProyectos.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


  </>


    )
}

export default EspGerenciaProyectos