import React from "react";
import bannertecseguridad from '../../img/facultades/tec_seguridad.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica";
import panta from '../../img/cursos/panta-sst.png';
import planeTecSst from '../../img/Plan-estudios/sst.png';


const TecSst =() => {

    const infoTecSst = [
        {
          Ficha: "El programa Técnico Profesional en Seguridad y Salud en el Trabajo de la Fundación Universitaria Horizonte le ofrece una formación académica que permite implemente acciones que logren mitigar cada uno de los riesgos laborales asociados a las actividades de una organización y de un puesto de trabajo para elevar los índices e indicadores del Sistema de Seguridad y Salud en el Trabajo SGSST en pro de mejorar la cultura de prevención de la empresa.",
          Perfil:"El Técnico Profesional en Seguridad y Salud en el Trabajo se caracteriza e identifica por ser un profesional con formación técnica integral que le permite observar, reconocer, controlar y realizar procedimientos, actividades y acciones que prevengan, minimicen y eliminen los riesgos laborales. Identificar e implementar al SG-SST variables que clarifiquen panoramas de riesgo logrando con ello disminuyan ‘+los niveles de accidentalidad y enfermedad en el trabajo con el fin de promover ambientes sanos en las organizaciones.",
          PerfilOcupacional:"El Técnico Profesional en Seguridad y Salud en el Trabajo de la Fundación Universitaria Horizonte podrá desempeñarse como: •	Coordinador de Seguridad y Salud en el Trabajo. •	Coordinador de Seguridad Industrial •	Implementador del SG-SST. •	Inspector de obra civil. •	Asistente Técnico y Operativo en Seguridad Industrial. •	Asistente Técnico de Higiene Industrial. •	Asistente Técnico / Coordinador en programas de Salud Ocupacional.",
          Denominación: "Técnico profesional en seguridad y salud en el trabajo",
          Titulo: "Técnico profesional en seguridad y salud en el trabajo",
          Modalidad: "Presencial",
          Creditos:"72 Créditos",
          Semestres:"4",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "111370",
          Registro: "014408",
          Matricula: "$2.966.200",
          planEstudio: planeTecSst,
          videoFondo: panta,
          Video: "https://www.youtube.com/embed/ZXPdsEyooUY",
          Facultad:"Facultad de Ingenierías",
          Telefono:"Tel: 7437270 Ext. 129",
          Email: "ingenieriadesoftware@unihorizonte.edu.co",
          certificacion: "Certificado en Riesgos Biológicos",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannertecseguridad})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoTecSst.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>

              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoTecSst.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }

  </>
    )
}

export default TecSst