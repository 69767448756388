import React from "react";
import bannersst from '../../img/facultades/seguridadsalud.jpg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_admon_sst.png';
import planeSst from '../../img/Plan-estudios/administracion-seguridad.png';





const Sst =() => {

    const infoSst  = [
        {
          Ficha: "En la Fundación Universitaria Horizonte formamos profesionales en Seguridad y Salud en el Trabajo altamente capacitados, en prevenir riesgos y promover el bienestar, mediante la aplicación de tecnologías innovadoras y un enfoque humanista. Nuestros egresados estarán equipados para prevenir riesgos, promover el bienestar laboral, creando ambientes de trabajo más seguros, saludables y productivos.",
          Perfil:"El Administrador en Seguridad y Salud en el Trabajo identifica, analiza y propone proyectos y/o estrategias orientadas al mantenimiento de la salud ocupacional, la seguridad industrial y la protección del medio ambiente. Identifica, analiza, desarrolla planes de atención y promoción en medicina laboral para organizaciones de los diferentes sectores económicos. Diagnostica y determina las brechas de seguridad y salud en el trabajo determinado por la normatividad nacional e internacional, con el propósito de determinar los planes de acción a corto, mediano y largo plazo.",
          PerfilOcupacional:"El profesional en Administración en Seguridad y Salud en el Trabajo de la Fundación Universitaria Horizonte podrá desempeñarse como: •	Gestor y administrador de la seguridad y salud en el trabajo. •	Promotor de sistemas integrados de gestión. •	Inspector de trabajo. •	Consultor y asesor de seguridad y salud en el trabajo. •	Investigador de accidentes de trabajo y enfermedades laborales. •	Gerente de administradoras de riesgos laborales •	Formador y capacitador en temas de seguridad y salud en el trabajo.",
          Denominación: "Administración en seguridad y Salud en el Trabajo",
          Titulo: "Administrador/a en seguridad y salud en el trabajo",
          Modalidad: "Virtual",
          Creditos:"150 Créditos",
          Semestres:"9",
          //Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "110805",
          Registro: "17591",
          Matricula: "$2.593.100",
          planEstudio: planeSst,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2U4CF0IWXe/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Con certificación en Análisis Laboral y Toma de Decisiones",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannersst})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoSst .map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoSst .map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default Sst