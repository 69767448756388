import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import '../css/documentos.css'
import bannerdocs from '../../src/img/doc-banner.jpeg'
import informeresultados2023 from '../../src/documentos/dian/INFORME_ANUAL_DE_RESULTADOS_2023.pdf'
import informeresultados2022 from '../../src/documentos/dian/Informe-Anual-De-Resultados_2022.pdf'
import informeresultados2017 from '../../src/documentos/dian/ACTUALIZACION_RTE_2017/1 INFORME DE GESTION ACTA Nº 61 DEL 23 DE ABRIL DE 2018.pdf'

import memoriaeconomica2023 from '../../src/documentos/dian/MEMORIA_ECONOMICA_2023.pdf'
import memoriaeconomica2022 from '../../src/documentos/dian/Memoria-Economica_2022.pdf'
import memoriaeconomica2017 from '../../src/documentos/dian/ACTUALIZACION_RTE_2017/11 MEMORIA ECONOMICA IV_2017.pdf'

import estadofinanciero2022 from '../../src/documentos/dian/ESTADOS-FINANCIEROS-Y-REVELACIONES-2022.pdf'
import estadofinanciero2023 from '../../src/documentos/dian/ESTADOS_FINANCIEROS_2023.pdf'
import estadofinanciero2017 from '../../src/documentos/dian/ACTUALIZACION_RTE_2017/2_ESTADOS_FINANCIEROS_2017.pdf'

import CertCargosDirectivos2023 from '../../src/documentos/dian/CERTIF_REMUNERACION_CARGOS_DIRECTIVOS_2023.pdf'
import CertCargosDirectivos2022 from '../../src/documentos/dian/Certif-De-Remunerado-De-Los-Cargos-Directivos_2022.pdf'

import cumplimientoRequisitos2023 from '../../src/documentos/dian/CERTIFICADO_DE_CUMPLIMIENTO_RTE_2023.pdf'
import cumplimientoRequisitos2022 from '../../src/documentos/dian/Certif-Cumplimiento-De-Requisitos-Para-Permanecer-RTE-2022.pdf';

import CertAntecdentesJud2023 from '../../src/documentos/dian/CERTIFICADO_DE_ANTECEDENTESJud_2023.pdf';
import CertAntecdentesJud2022 from '../../src/documentos/dian/Certif-Antecedentes-Judiciales-Y-Caducidad-De-Contratos2022.pdf';
import CertAntecdentesJud2017 from '../../src/documentos/dian/ACTUALIZACION_RTE_2017/8 CERTIFICACION REPRESENTANTE LEGAL ANTECEDENTES JUDICIALES_2017.pdf';


import Rut5425_RTE from '../../src/documentos/dian/RUT_52451002282206_5245_2024.pdf';



const Documentos = () => {
    return(
      <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerdocs})`}}>
          <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
          </div>
        </div>
        <section id="docs" className="docs">
          <div className="container" data-aos="fade-up">

            <div className="col-lg-12">
              <h1>RTE DIAN AÑO 2024</h1>
            </div>

            <Accordion defaultActiveKey="0">
             
             
            <Accordion.Item eventKey="11">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>INFORME ANUAL DE RESULTADOS</Accordion.Header>
                <Accordion.Body>
                  <a href={informeresultados2023} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Informe Anual de resultados 2023
                  </a>

                  <a href={informeresultados2022} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Informe Anual de resultados 2022
                  </a>


                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="0">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i> MEMORIA ECONÓMICA</Accordion.Header>
                <Accordion.Body>
                  <a href={memoriaeconomica2023} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Memoria Económica 2023
                  </a>
                  <a href={memoriaeconomica2022} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Memoria Económica 2022
                  </a>

                  <a href={memoriaeconomica2017} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Memoria Económica 2017
                  </a>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>ESTADOS FINACIEROS</Accordion.Header>
                <Accordion.Body>
                  <a href={estadofinanciero2023} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Estados Financieros 2023
                  </a>
                  <a href={estadofinanciero2022} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Estados Financieros 2022
                  </a>
                  <a href={estadofinanciero2017} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Estados Financieros 2017
                  </a>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="2">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>CERTIFICACION REMUNERACION CARGOS DIRECTIVOS</Accordion.Header>
                <Accordion.Body>
                  <a href={CertCargosDirectivos2023} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Certificación Remuneración Cargos Directivos 2023
                  </a>

                  <a href={CertCargosDirectivos2022} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Certificación Remuneración Cargos Directivos 2022
                  </a>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="3">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>CUMPLIMIENTO DE REQUISITOS RTE</Accordion.Header>
                <Accordion.Body>
                <a href={cumplimientoRequisitos2023} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Cumplimiento de Requisitos 2023
                  </a>
                  <a href={cumplimientoRequisitos2022} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Cumplimiento de Requisitos 2022
                  </a>
              
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="4">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>CERTIFICACION DE ANTECEDENTES JUDICIALES</Accordion.Header>
                <Accordion.Body>
                  <a href={CertAntecdentesJud2023} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Certificación Antecedentes Judiciales 2023
                  </a>
                  <a href={CertAntecdentesJud2022} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Certificación Antecedentes Judiciales 2022
                  </a>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="5">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>FORMATO 5245 RTE</Accordion.Header>
                <Accordion.Body>
                  <a href={Rut5425_RTE} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Formato 5242 2023
                  </a>
                </Accordion.Body>
              </Accordion.Item>              
            


            <Accordion.Item eventKey="5">
                <Accordion.Header><i className="bi bi-cloud-download-fill icon-help"></i>2017</Accordion.Header>
                <Accordion.Body>
                <a href={informeresultados2017} target="_blank" >
                    <i className="bi bi-file-earmark-arrow-down icon-download"></i>Informe Anual de resultados 2017
                  </a>
                </Accordion.Body>
              </Accordion.Item>              
              </Accordion>


          </div>
        </section>
      </>
    )
}

export default Documentos