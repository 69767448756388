import React from "react";
import bannerava from '../../../img/facultades/ova.jpg';
import Pasos from "../../../componentes/programas/Pasos";
import {Contacto} from "../../../componentes/programas/Contacto";
import {Fichatecnica} from "../../../componentes/programas/FichaTecnica";
import gastrocurse from '../../../img/cursos/5razonesava.jpg';
import planeEspAva from '../../../img/Plan-estudios/ava.png';





const EspAva =() => {

    const infoEspAva = [
        {
          Ficha: "La Especialización en Ambientes Virtuales de Aprendizaje de HORIZONTE ofrece una sólida formación en diseño instruccional, creación de cursos virtuales, gestión de plataformas educativas virtuales y la integración de las TIC en el proceso educativo, ofreciendo una experiencia de calidad a los estudiantes y la apropiación de metodologías innovadoras de enseñanza y aprendizaje para responder a las demandas tecnológicas y pedagógicas",
          Perfil:"•	Especialista en diseñar y construir objetos virtuales de aprendizaje y gestionar sistemas educativos virtuales. •	Ético formado en competencias tecnológicas, pedagógicas y humanísticas para la apropiación de las TIC en procesos de enseñanza-aprendizaje. •	Observador y crítico de las realidades y contextos para generar soluciones a través de estrategias y metodologías innovadoras de enseñanza y aprendizaje mediadas por las TIC .",  
          PerfilOcupacional:"•	Acreditar título profesional en educación, pedagogía, tecnologías de información, ingenierías, comunicación o áreas relacionadas y afines. •	Acreditar experiencia en educación mediada por tecnologías, ya sea como estudiante, tutor o gestor. •	Poseer experiencia como educador, ya sea en la docencia, la capacitación, la organización social, la promoción comunitaria, la tutoría o la orientación.",
          Denominación: "Especialización en Ambientes Virtuales de Aprendizaje, AVA",
          Titulo: "Especialista en Ambientes Virtuales de Aprendizaje, AVA",
          Modalidad: "Virtual",
          Creditos:"26 Créditos académicos",
          Semestres:"2",
          Jornada: "Virtual",
          Ciudad: "Bogotá",
          Snies: "110606",
          Registro: "12973",
          Matricula: "$6.482.900",
          planEstudio: planeEspAva,
          videoFondo: gastrocurse,
          Video: "https://www.youtube.com/watch?v=Sh_eRIN6rcY",
          Facultad:"Facultad de Posgrados",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracion@unihorizonte.edu.co",
          certificacion: "Certificación en Docencia Universitaria",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerava})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoEspAva.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoEspAva.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default EspAva