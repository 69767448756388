import React from "react";
import banneradmon from '../../img/facultades/admonEmpre.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_admon_empresas.png';
import planeAdmon from '../../img/Plan-estudios/admi-Empresas.png';





const AdminEmpresas =() => {

    const infoAdminEmpresas = [
        {
          Ficha: "El Profesional en Administración de Empresas de la Fundación Universitaria Horizonte cuenta con habilidades y competencias para planificar, organizar, dirigir y controlar las actividades de una organización con conocimientos versátiles para resolver asuntos cotidianos de manera lógica y racional, desarrollando habilidades prácticas para crear y gestionar negocios, adaptándose a las tendencias del mercado global",
          Perfil:"El Administrador de Empresas comprenderá las tendencias del mercado, con un amplio conocimiento teórico - práctico con gestión estratégica, análisis financiero y marketing digital. Será emprendedor por naturaleza, capaz de generar ideas integrando las nuevas tecnologías para el desarrollo sostenible de las organizaciones; su enfoque está orientado al desarrollo sostenible y la responsabilidad social empresarial",
          PerfilOcupacional:"El Administrador de Empresas de la Fundación Universitaria Horizonte podrá desempeñarse en cualquier sector económico en cargos como: •	Asesor, consultor, auditor o analista en cualquier tipo de empresa ya sea pública o privada. •	Emprendedor desarrollando proyectos innovadores. •	Gerente General de una organización. •	Director General. •	Director Administrativo. •	Director Financiero .",
          Denominación: "Administración de empresas",
          Titulo: "Administrador/a de empresas",
          Modalidad: "Presencial",
          Creditos:"149 Créditos",
          Semestres:"8",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "107214",
          Registro: "12020",
          Matricula: "$4.530.450",
          planEstudio: planeAdmon,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2FbQuXMJfT/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Con certificación en Ciencia de Datos",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${banneradmon})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoAdminEmpresas.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoAdminEmpresas.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default AdminEmpresas