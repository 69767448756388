import React from "react";
import'../../../css/econtinuada.css';
import {Tab, Row, Col, Nav} from 'react-bootstrap';
import {EcontinuaCard} from '../../../componentes/educacion_continua/EcontinuaCard'
import bannering from '../../../img/facultades/educacion_continua.jpg';
import cursos4 from '../../../img/econtinuada/cursos/cocina_saludable.jpg';
//import cursos5 from '../../../img/econtinuada/cursos/cocina_navideña.jpg';
import cursos6 from '../../../img/econtinuada/cursos/cocina_Internacional.jpg';
import cursos7 from '../../../img/econtinuada/cursos/gamificacion.png';
import cursos8 from '../../../img/econtinuada/cursos/robotica.png';
import cursos9 from '../../../img/econtinuada/cursos/analitica_de_datos.jpg';
import cursos10 from '../../../img/econtinuada/cursos/luxometria.jpg';
import cursos11 from '../../../img/econtinuada/cursos/gestion_de_emergencias.png';
import cursos12 from '../../../img/econtinuada/cursos/indicadores_SST.png';
import cursos13 from '../../../img/econtinuada/cursos/periodismo_economico.jpg';
import cursos14 from '../../../img/econtinuada/cursos/periodismo_politico.jpg';
import cursos15 from '../../../img/econtinuada/cursos/textos_redes.jpg';
import cursos16 from '../../../img/econtinuada/cursos/modelado_3d.jpg';
import diplomado1 from '../../../img/econtinuada/diplomados/pmbok.png';
import diplomado2 from '../../../img/econtinuada/diplomados/diplomado_mercado_financiero_y_de_valores.png';
import diplomado4 from '../../../img/econtinuada/diplomados/diplomado_dulce.jpg';
import diplomado5 from '../../../img/econtinuada/diplomados/Diplomado_ISO_45001.png';
//import diplomado7 from '../../../img/econtinuada/diplomados/restauracion_ambiental.jpg';
import diplomado8 from '../../../img/econtinuada/diplomados/gerencia_social.jpg';
import taller1 from '../../../img/econtinuada/talleres/cubrimiento_de_eventos_deportivos.jpg';
import taller2 from '../../../img/econtinuada/talleres/tecnicas_basicas_de_ilustracion.png';
import taller3 from '../../../img/econtinuada/talleres/taller_de_la_felicidad.jpg';
import taller4 from '../../../img/econtinuada/cursos/taller_de_chocolateria.png';
import taller5 from '../../../img/econtinuada/cursos/enologia_y_maridaje.png';

import cpanalisislaboral from '../../../img/cp/admon/CPAnalisisLaboral.png';
import cpcontabilidadintegrada from '../../../img/cp/admon/CPContabilidadIntegrada.png';
import cpfinanzasglobales from '../../../img/cp/admon/CPFinanzasGlobales.png';
import cpgerencialcontinua from '../../../img/cp/admon/CPGerencialContinua.png';
import cpgestionempresas from '../../../img/cp/admon/CPGestionEmpresas.png';
import cphorizonteetico from '../../../img/cp/admon/CPHorizonteEtico.png';
import cpliderazgointegral from '../../../img/cp/admon/CPLiderazgoIntegral.png';
import cpmenteglobal from '../../../img/cp/admon/CPMenteGlobal.png';
import cpmercadosglobales from '../../../img/cp/admon/CPMercadosGLobales.png';
import cpregistrosresultados from '../../../img/cp/admon/CPRegistrosResultados.png';
import cprutaglobal from '../../../img/cp/admon/CPRutaGlobal.png';
import cptributacionfinanzas from '../../../img/cp/admon/CPTributacionFinanzas.png';
import cpilustracion from '../../../img/cp/comunicacion/CPIlustracion.png';
import cpimagenredes from '../../../img/cp/comunicacion/CPImagenRedes.png';
import cpreporteria from '../../../img/cp/comunicacion/CPReporteria.png';
import cppasteleria from '../../../img/cp/gastronomia/CPPasteleria.png';
import cpvinoscocteles from '../../../img/cp/gastronomia/CPVinosCocteles.png';
import cpfactoresbiologicos from '../../../img/cp/ingenieria/CPFactoresBiologicos.png';
import cprestauracionecologica from '../../../img/cp/ingenieria/CPRestauracionEcologica.png';
import cpfundamentoscriminalistica from '../../../img/cp/juridica/CPFundamentosCriminalistica.png';
import cptecnologiainnovacion from '../../../img/cp/juridica/CPTecnologiaInnovacion.png';

const EducacionContinua = () =>{

    const econtinuadaCursos = [

        {
            title: "Cocina Saludable",
            imgContinua: cursos4,
            link:"https://biz.payulatam.com/L0ce7162BEB9E52",
        },
        /*{
            title: "Cocina Navideña",
            imgContinua: cursos5,
            link:"https://biz.payulatam.com/L0ce716DACEB943",
        },*/
        {
            title: "Cocina Internacional",
            imgContinua: cursos6,
            link:"https://biz.payulatam.com/L0ce7164097FCA5",
        },
        {
            title: "Pensamiento creativo mediante Gamificación",
            imgContinua: cursos7,
            link:"https://biz.payulatam.com/L0ce716084CC013",
        },
        {
            title: "Curso básico de Programación en Robótica",
            imgContinua: cursos8,
            link:"https://biz.payulatam.com/L0ce716002FE1EB",
        },
        {
            title: "Curso Analítica de datos aplicada a tu profesión",
            imgContinua: cursos9,
            link:"https://biz.payulatam.com/L0ce7162009EF91",
        },
        {
            title: "Curso-Taller Luxometria",
            imgContinua: cursos10,
            link:"https://biz.payulatam.com/L0ce7166DA020B8",
        },
        {
            title: "Gestion de Emergencias",
            imgContinua: cursos11,
            link:"https://biz.payulatam.com/L0ce7168F81784D",
        },
        {
            title: "Indicadores de Gestión de Información SST",
            imgContinua: cursos12,
            link:"https://biz.payulatam.com/L0ce716AFA617A8",
        },
        {
            title: "Fundamentos de Periodismo Económico",
            imgContinua: cursos13,
            link:"https://biz.payulatam.com/L0ce716D53C80F7",
        },
        {
            title: "Curso en Periodismo Político",
            imgContinua: cursos14,
            link:"https://biz.payulatam.com/L0ce716EA529975",
        },
        {
            title: "Redacción de textos para redes sociales ",
            imgContinua: cursos15,
            link:"https://biz.payulatam.com/L0ce71645196B60",
        },
        {
            title: "Curso Modelado 3d personajes fantásticos en porcelanicron",
            imgContinua: cursos16,
            link:"https://biz.payulatam.com/L0ce71645F69DF7",
        },
    ]

    const econtinuadaDiplomados = [
        {
          title: "Gerencia social",
          imgContinua: diplomado8,
          link:"",
        },
        {
          title: "Gestión de preyectos según el marco de trabajo PMBOK",
          imgContinua: diplomado1,
          link:"https://biz.payulatam.com/L0ce716EE77D9D6",
        },
        {
            title: "Diplomado en Mercado financiero y valores en Colombia",
            imgContinua: diplomado2,
            link:"https://biz.payulatam.com/L0ce716C3E628D4",
        },
        {
            title: "Diplomado en tendencias y creatividad del mundo dulce",
            imgContinua: diplomado4,
            link:"https://biz.payulatam.com/L0ce71672165ABB",
        },
        {
            title: "Diplomado implementación ISO 45001",
            imgContinua: diplomado5,
            link:"https://biz.payulatam.com/L0ce7166E31381C",
        },
        /*{
            title: "Restauración Ambiental y Ecológica",
            imgContinua: diplomado7,
            link:"https://biz.payulatam.com/L0ce716D11AA767",
        },*/
    ]

    const econtinuadaTalleres = [
        {
          title: "Taller de Chocolatería",
          imgContinua: taller4,
          link:"https://biz.payulatam.com/L0ce716F7B19EB3",
        },
        {
          title: "Enología y Maridaje",
          imgContinua: taller5,
          link:"https://biz.payulatam.com/L0ce7161D7838BD",
        },
        {
          title: "Cubrimiento de Eventos Deportivos",
          imgContinua: taller1,
          link:"https://biz.payulatam.com/L0ce716BF748E23",
        },
        {
            title: "Técnicas Básicas de Ilustración",
            imgContinua: taller2,
            link:"https://biz.payulatam.com/L0ce7168B508742",
        },
        {
          title: "Taller de la Felicidad",
          imgContinua: taller3,
          link:"https://biz.payulatam.com/L0ce7163B339419",
      },
    ]

    const econtinuadacertificaciones = [

      {
          title: "Análisis laboral y toma de decisiones",
          imgContinua: cpanalisislaboral,
          link:"https://biz.payulatam.com/L0ce716068AA568",
      },
      /*{
          title: "Cocina Navideña",
          imgContinua: cursos5,
          link:"https://biz.payulatam.com/L0ce716DACEB943",
      },*/
      {
          title: "Contabilidad Integrada: Contextos y Convergencias",
          imgContinua: cpcontabilidadintegrada,
          link:"https://biz.payulatam.com/L0ce716F1C18317",
      },
      {
          title: "Estrategias y Finanzas Globales",
          imgContinua: cpfinanzasglobales,
          link:"https://biz.payulatam.com/L0ce716B71F07CF",
      },
      {
          title: "Transformación Gerencial Continua",
          imgContinua: cpgerencialcontinua,
          link:"https://biz.payulatam.com/L0ce71615EFB391",
      },
      {
          title: "Gestión Inteligente en Empresas",
          imgContinua: cpgestionempresas,
          link:"https://biz.payulatam.com/L0ce716B80A26DA",
      },
      {
          title: "Construyendo un Horizonte ético",
          imgContinua: cphorizonteetico,
          link:"https://biz.payulatam.com/L0ce71612D832CB ",
      },
      {
          title: "Liderazgo Integral en Recursos Humanos y Gestión Organizacional",
          imgContinua: cpliderazgointegral,
          link:"https://biz.payulatam.com/L0ce716DA8535E1",
      },
      {
          title: "Mente Global: Decodificando el Mundo Contemporáneo",
          imgContinua: cpmenteglobal,
          link:"https://biz.payulatam.com/L0ce716D9508ECA",
      },
      {
          title: "Explorando Mercados Globales: Claves del Éxito",
          imgContinua: cpmercadosglobales,
           link:"https://biz.payulatam.com/L0ce7162912380D",
      },
      {
          title: "Contabilidad Transformadora: De Registros a Resultados",
          imgContinua: cpregistrosresultados,
          link:"https://biz.payulatam.com/L0ce71689893D28",
      },
      {
          title: "Ruta Global: Navegando las Corrientes de la Logística Internacional",
          imgContinua: cprutaglobal,
          link:"https://biz.payulatam.com/L0ce71678A8AAAB",
      },
      {
          title: "Impulsa tus Finanzas: Tributación y Finanzas",
          imgContinua: cptributacionfinanzas,
          link:"https://biz.payulatam.com/L0ce716696C98F9",
      },
      {
          title: "Ilustración",
          imgContinua: cpilustracion,
          link:"https://biz.payulatam.com/L0ce716AEB1B060",
      },
      {
          title: "Imagen para redes Sociales",
          imgContinua: cpimagenredes,
          link:"https://biz.payulatam.com/L0ce71674C0139F",
      },
      {
          title: "Reportería para Redes Sociales",
          imgContinua: cpreporteria,
          link:"https://biz.payulatam.com/L0ce71613F8DC5A",
      },
      {
          title: "Pastelería de Vanguardia",
          imgContinua: cppasteleria,
          link:"https://biz.payulatam.com/L0ce7163635F02C",
      },
      {
          title: "Vinos y Cócteles",
          imgContinua: cpvinoscocteles,
          link:"https://biz.payulatam.com/L0ce716CE280FAB",
      },
      {
          title: "Factores Biológicos",
          imgContinua: cpfactoresbiologicos,
          link:"https://biz.payulatam.com/L0ce71616FEA8F9",
      },
      {
          title: "Restauración Ecológica",
          imgContinua: cprestauracionecologica,
          link:"https://biz.payulatam.com/L0ce716D54D385C",
      },
      {
          title: "Fundamentación Básica de Criminalística",
          imgContinua: cpfundamentoscriminalistica,
          link:"https://biz.payulatam.com/L0ce71639EE7B82",
      },
      {
          title: "Derecho Tecnología e innovación",
          imgContinua: cptecnologiainnovacion,
          link:"https://biz.payulatam.com/L0ce716349A962A",
      },
      
  ]

    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannering})`}}>
          <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade"></div>
        </div>

   
    <section id="econtinuada" className="econtinuada">
      <div className="container" data-aos="fade-up">

        <div className="row position-relative">
          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
            <h2>Educación Continua</h2>
          </div>

        <div className=" ">

        <div className="col-lg-12 col-xs-6 content d-flex flex-column order-last order-md-first">
            

        <div className="econtinuada_items">
            <Tab.Container 
            id="left-tabs-example" 
            defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first"> Cursos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Diplomados</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="thirt">Talleres</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="forth">Certificaciones</Nav.Link>
                    </Nav.Item>

                  </Nav>

                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                    <div className="row gy-5" >
                    
                    {
                          econtinuadaCursos.map((div, index) => {
                          return (
                              <EcontinuaCard
                               key={index}
                               {...div}
                              />
                           )
                         })
                        }                   
                    </div>

                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <div className="row gy-5" >
                    
                    {
                          econtinuadaDiplomados.map((div, index) => {
                          return (
                              <EcontinuaCard
                               key={index}
                               {...div}
                              />
                           )
                         })
                        }                   
                    </div>

                      </Tab.Pane>
                    <Tab.Pane eventKey="thirt">
                    <div className="row gy-5" >
                    
                    {
                          econtinuadaTalleres.map((div, index) => {
                          return (
                              <EcontinuaCard
                               key={index}
                               {...div}
                              />
                           )
                         })
                        }

                        </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="forth">
                    <div className="row gy-5" >
                    
                    {
                          econtinuadacertificaciones.map((div, index) => {
                          return (
                              <EcontinuaCard
                               key={index}
                               {...div}
                              />
                           )
                         })
                        }

                        </div>
                    </Tab.Pane>


                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            </div>
            
          </div>     

        </div>
        </div>
        </div>
        </section>
        </>
    )
};

export default EducacionContinua