import React from "react"


export const CardInnovacion=({title, imgback, texto, boton})=> {
    return(
        <>
          <div className="contenedor col-sm-6  " data-aos="zoom-in" data-aos-delay="200">
            <div className="service-item">
              <div className="title-card">
                <h3>{title}</h3>
              </div>
              <div className="img">
                <img src={imgback} className="img-fluid" alt=""/>
              </div>
              <div className=" texto-info justify-content">
                <p>{texto}</p>
              </div>
              <div>
                <a className="but-innovacion">{boton}</a>
              </div>
            </div>
          </div>
        </>
    )
} 