import React from "react";
import banneringindustrial from '../../img/facultades/inge_industrial.jpeg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_ing_sst.png';
import planeIngSiho from '../../img/Plan-estudios/ing-siho.jpg';


const IngSiho =() => {

    const infoIngSiho = [
        {
          Ficha:"El programa Ingeniería en Seguridad y Salud en el Trabajo de la Fundación Universitaria Horizonte proporciona a sus estudiantes conocimientos y habilidades que le permitan establecer y desarrollar procesos en prevención de riesgos laborales desde una visión ingenieril logrando con ello, implementar estrategias de mitigación y eliminación del riesgo y modelos de innovación en procesos propios de la seguridad y salud laboral que permitirán mejorar los indicadores organizacionales y la eficiencia productiva de las empresas.",
          Perfil:"El Ingeniero en Seguridad y Salud en el Trabajo se caracteriza por ser un profesional analítico, alineado con la legislación vigente, con las técnicas y prácticas de la ingeniería y con la convicción de desarrollar y elevar los niveles de seguridad en las organizaciones, con la capacidad de liderar procesos en el contexto de la Seguridad Industrial e Higiene, para coordinar, mantener y dirigir proyectos, desarrollos, diseños e innovaciones de Seguridad y Salud en el Trabajo en las empresas y organizaciones a nivel nacional, local o regional. Aplica su conocimiento ingenieril para implementar actividades, acciones, métodos, metodologías, herramientas, diseños y/o desarrollos en el área, con el fin de mejorar el bienestar y la salud de los trabajadores.",
          PerfilOcupacional: "El ingeniero en Seguridad y Salud en el Trabajo de la Fundación Universitaria Horizonte estará capacitado para desempeñarse en organizaciones públicas o privadas de los diferentes sectores económicos como: •	Gerente o jefe de departamentos de Seguridad y Salud en el Trabajo en las organizaciones. •	Gerente o jefe de Proyectos en SST en las organizaciones. •	Ingeniero asesor en SST en las organizaciones •	Coordinador de Seguridad Industrial e Higiene en las organizaciones. •	Ingeniero de proyectos SST en las organizaciones.",
          Denominación: "Ingeniería en Seguridad y Salud en el Trabajo",
          Titulo: "Ingeniero/a en Seguridad y Salud en el Trabajo",
          Modalidad: "Presencial",
          Creditos:"153 Créditos",
          Semestres:"9",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "104564",
          Registro: "024102",
          Matricula: "$5.294.850",
          planEstudio: planeIngSiho,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2OVLu2IPms/?img_index=1",
          Facultad:"Facultad de Ingenierías",
          Telefono:"Tel: 7437270 Ext. 129",
          Email: "ingenieriadesoftware@unihorizonte.edu.co",
          certificacion: "Certificado en Bienestar Laboral",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${banneringindustrial})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoIngSiho.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                    <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                    <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                    </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoIngSiho.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


  </>

    )
}

export default IngSiho