import React from "react";
import bannerSst from '../../../img/facultades/sst.jpg';
import Pasos from "../../../componentes/programas/Pasos";
import {Contacto} from "../../../componentes/programas/Contacto";
import {Fichatecnica} from "../../../componentes/programas/FichaTecnica";
import gastrocurse from '../../../img/cursos/gastro-course.jpg';
import planeEspSst from '../../../img/Plan-estudios/esp-sst.png';





const EspGsst =() => {

    const infoEspGsst = [
        {
          Ficha: "La Especialización en Gerencia de la Seguridad y Salud en el Trabajo de la Fundación Universitaria Horizonte permite la generación de soluciones innovadoras a nivel gerencial para disminuir y/o eliminar los diversos riesgos a nivel laboral presentes en las organizaciones y con ello impactar positivamente en la productividad de estas.",
          Perfil:"El Especialista en Gerencia de la Seguridad y Salud en el Trabajo estará en la capacidad de aplicar los conocimientos, destrezas, actitudes y aptitudes que le permitan plantear diagnósticos, realizar pronósticos, medir situaciones y aplicar la normatividad vigente a las situaciones adversas, nocivas o problemáticas presentadas sobre el SGSST y que afecten la salud y bienestar de los trabajadores. Realizar y plantear proyectos de investigación en la disciplina. Gestionar, controlar, direccionar, liderar y gerenciar los procesos administrativos, técnicos y científicos del SGSST.",
          PerfilOcupacional:"El aspirante al programa de Especialización en Gerencia de la Seguridad y Salud en el Trabajo es un profesional con interés hacia las áreas de riesgos laborales, seguridad industrial, salud e higiene ocupacional y legislación vigente en la disciplina. Debe contar con habilidades blandas, habilidades fundamentales y básicas en el manejo de Tecnologías de la Información y la comunicación.",
          Denominación: "Especialización en Gerencia de la Seguridad y Salud en el Trabajo",
          Titulo: "Especialista en Gerencia de la Seguridad y Salud en el Trabajo",
          Modalidad: "Presencial",
          Creditos:"28 Créditos",
          Semestres:"2",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "109186",
          Registro: "15616",
          Matricula: "$7.651.700",
          planEstudio: planeEspSst,
          videoFondo: gastrocurse,
          Video: "https://www.youtube.com/embed/LXb3EKWsInQ",
          Facultad:"Facultad de Posgrados",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Certificación en Ambientes Laborales Productivos",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannerSst})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoEspGsst.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoEspGsst.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default EspGsst