import React from "react";
import bannertecgestionambiental from '../../img/facultades/ambiental.jpg';
import Pasos from "../../componentes/programas/Pasos";
import {Contacto} from "../../componentes/programas/Contacto";
import {Fichatecnica} from "../../componentes/programas/FichaTecnica2";
import gastrocurse from '../../img/cursos/razones_tec_ambiental.png';
import planeTecGestionAmbi from '../../img/Plan-estudios/tecnologia-gestion-ambiental.png';


const TecGestionAmbiental =() => {

    const infoTecGestionAmbi = [
        {
          Ficha: "La tecnología en Gestión Ambiental de la Fundación Universitaria Horizonte permite al estudiante adquirir los conocimientos propios para resolver asuntos relacionados con problemáticas ambientales de manera lógica y racional, a través de la implementación de estrategias que permitan el aprovechamiento y uso sostenible de los recursos naturales.",
          Perfil:"El Tecnólogo en Gestión Ambiental se caracteriza por ser un profesional crítico, creativo, comprometido y capacitado para prevenir, mitigar, solucionar y controlar efectos ambientales dentro y fuera de la organización. Favorecer la conservación, protección, manejo y uso responsable de los recursos naturales, diseñando y gestionando soluciones ambientales que sensibilicen las cadenas de producción y de consumo para el mejoramiento de la calidad de vida en comunidad.",
          PerfilOcupacional:"El egresado del programa de Tecnología en Gestión Ambiental de la Fundación Universitaria Horizonte de podrá desempeñarse como: •  Gestor de áreas y unidades de negocio relacionadas con la producción de bienes y/o servicios a nivel ambiental. • Planificador y organizador de proyectos ambientales, con énfasis social y/o económico que permitan el cumplimiento y alcance de las metas de la organización.",
          Denominación: "Tecnología en Gestión Ambiental",
          Titulo: "Tecnólogo/a en gestión ambiental",
          Modalidad: "Presencial",
          Creditos:"103 Créditos",
          Semestres:"6",
          Jornada: "Diurno / Nocturno /Sábado",
          Ciudad: "Bogotá",
          Snies: "108509",
          Registro: "14523",
          Matricula: "$3.298.150",
          planEstudio: planeTecGestionAmbi,
          videoFondo: gastrocurse,
          link: "https://www.instagram.com/p/C2IADHnyokk/?img_index=1",
          Facultad:"Facultad de ciencias administrativas",
          Telefono:"Tel: 7437270 Ext. 149",
          Email: "administracíon@unihorzionte.edu.co",
          certificacion: "Certificación en Restauración Ecológica",
        },

        
      ]
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannertecgestionambiental})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

            <section id="cursos" className="cursos ">
            <div className="container" data-aos="fade-up">
              <div className="row position-relative ">
                
                  </div>
                  {
                          infoTecGestionAmbi.map((div, index) => {
                            return (
                              <Fichatecnica
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', height: '7vh' }}>
                  <a href="https://unihorizonte.edu.co/Financiamiento" target="_blank" style={{ textDecoration: 'none' }}>
                   <button className="btn btn-primary" style={{ fontSize: '1.5em', padding: '15px 25px' }}>Explora nuestras opciones de financiamiento</button>
                  </a>
                  </div>
              </section>
            
              <section className=" bg-section " >
              <div className="container d-flex" data-aos="fade-up">
              <Pasos/>
              </div>
              </section>
              
              {
                          infoTecGestionAmbi.map((div, index) => {
                            return (
                              <Contacto
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }


              




  </>


    )
}

export default TecGestionAmbiental