import React from "react";
import '../css/quienes_somos.css'
import about1 from'../../src/img/about_1.png'
import banner from '../../src/img/quienes_somos.jpeg'
import info1 from '../../src/img/about/prin-about-1.png'
import info2 from '../../src/img/about/prin-about.png'

const QuienesSomos = () => {
    return(
        <>
        <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${banner})`}}>
          <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
          </div>
        </div>
        

      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
  
          <div className="row position-relative">
  
            <div className="col-lg-6 about-img" style={{backgroundImage:`url(${about1})`}}></div>
  
            <div className="col-lg-6">
              <div className="our-story">
                <h3>Reseña Histórica</h3>
                <p>La<strong> FUNDACIÓN UNIVERSITARIA HORIZONTE – UNIHORIZONTE -</strong>, se presenta a la comunidad educativa en el 2014, año en el que el Ministerio de Educación Nacional, a través de la Resolución No. 9432 del 19 de junio, autoriza a la <strong> FUNDACIÓN INSTITUTO SUPERIOR DE CARRERAS TÉCNICAS –INSUTEC-</strong> el cambio en su denominación y nivel según lo establecido en la Ley 30 de 1992 y otras normas vigentes, para orientar su quehacer educativo a la formación de profesionales como seres humanos íntegros, que desarrollen su proyecto de vida a la luz de las concepciones humanistas contemporáneas, y que reivindiquen las dimensiones de la formación universitaria, como son la autonomía, la diversidad integrada, lo cognitivo, lo estético, lo axiológico y lo espiritual, entre otras, y las cuales están direccionadas hacia el desarrollo humano y la experiencia cotidiana de la Felicidad, entendidas estas dimensiones como los presupuestos básicos y a través de los cuales se forman personas dignas, autónomas y exitosas, capaces de transformar la realidad que les rodea por medio de su propia experiencia personal y del desarrollo de las competencias complejo-humanísticas, en el contexto del modelo <strong>pedagógico constructivista</strong> y del <strong> enfoque del aprendizaje significativo</strong>, pilares de sus procesos formativos como lo indica su diseño curricular (UNIHORIZONTE, 2018 b).</p>
  
                <p>
                  Una vez alcanzado el cambio de carácter,<strong> UNIHORIZONTE</strong> inicia ante al Ministerio de Educación Nacional el proceso de solicitud de registros calificados para la aprobación de programas académicos del nivel universitario, siendo la<strong> Ingeniería en Seguridad e Higiene Ocupacional</strong> el primer Programa requerido y aprobado mediante Resolución 7596 del 27 de mayo de 2015.
                </p>
                
              </div>
            </div>
            
          </div>
  
        </div>
      </section>

      <section id="principios" className="principios">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="zoom-in" data-aos-delay="100">

              <h3>Visión</h3>
              <div className="icon-box position-relative" data-aos="fade-up" data-aos-delay="100">
                <p> La Fundación Universitaria Horizonte será reconocida en el 2026 en el ámbito nacional e
                    internacional, como una Institución de Educación Superior de calidad por la formación de
                    estudiantes con un horizonte claro orientado a la felicidad, el bilingüismo, la innovación, la
                    tecnología y el emprendimiento 
                </p>
              </div>
            </div>

            
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3>Misión</h3>
              <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                <p> La Fundación Universitaria Horizonte es una institución de educación superior de calidad que
                    contribuye a la formación integral de los estudiantes mediante procesos de docencia, investigación
                    y proyección social, brindando herramientas para el desarrollo progresivo de un horizonte de
                    felicidad en su proyecto de vida, así como un horizonte de oportunidades mediante el fomento del
                    bilingüismo, la innovación, la tecnología y el emprendimiento.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="principios" className="principios">
          <div className="container" data-aos="fade-up">

            <div className="row justify-content-around gy-4">
              <div className="col-lg-6 img-bg" style={{backgroundImage:`url(${info2})`}} data-aos="zoom-in" data-aos-delay="100"></div>
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <h3>Principios Institucionales</h3>
                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                  <i className="bi bi-emoji-laughing flex-shrink-0"></i>
                  <div>
                    <h4>La Felicidad:</h4>
                    <p> 
                      La Fundación Universitaria Horizonte será reconocida en el 2026 en el ámbito nacional e
                      internacional, como una Institución de Educación Superior de calidad por la formación de
                      estudiantes con un horizonte claro orientado a la felicidad, el bilingüismo, la innovación, la
                      tecnología y el emprendimiento
                    </p>
                  </div>
                </div>

                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                  <i className="bi bi-patch-check flex-shrink-0"></i>
                  <div>
                    <h4>El Desarrollo del Talento:</h4>
                    <p>
                      Orientado a propender por la felicidad en quienes emprenden
                      proyectos de vida con la Institución, se promueven oportunidades de desarrollo en
                      capacitación, crecimiento laboral, así como incentivos que conduzcan a mantener
                      una actitud de servicio.
                    </p>
                  </div>
                </div>

                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                  <i className="bi bi-hand-thumbs-up flex-shrink-0"></i>
                  <div>
                    <h4>La Pasión:</h4>
                    <p> 
                      Es la expresión de la motivación y el entusiasmo por emprender retos
                      personales y colectivos, permitiendo a los colaboradores el empoderamiento con
                      una autonomía responsable y de calidad, se ama lo que se hace y se demuestra con
                      la calidad en el servicio.
                    </p>
                  </div>
                </div>

                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                  <i className="bi bi-award flex-shrink-0"></i>
                  <div>
                    <h4>El Desarrollo Local:</h4>
                    <p>
                      Impactamos comunidades en el entorno local, participamos en
                      proyectos que contribuyan al desarrollo de la sociedad, los egresados muestran su
                      formación integral cuando comprenden que ser feliz es hacer feliz a otros.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>

      <section id="principios" className="principios ">
        <div className="container" data-aos="fade-up">

          <div className="row justify-content-around gy-4">
            <div className="col-lg-5 d-flex flex-column justify-content-center">
              <h3>Valores Institucionales</h3>

              <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="100">
                <i className="bi bi-flag flex-shrink-0"></i>
                <div>
                  <h4>La Perseverancia:</h4>
                  <p>
                    La Institución asume sus tareas con constancia, esfuerzo y
                    dedicación, hasta conseguir los objetivos propuestos, se hace fuerte en la medida
                    que enfrenta retos y se reinventa según la realidad que se vive.
                  </p>
                </div>
              </div>

              <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-palette flex-shrink-0"></i>
                <div>
                  <h4>La Adaptabilidad:</h4>
                  <p>
                    La Institución se fortalece en el pensamiento creativo y el
                    pensamiento crítico entendiendo los cambios constantes del mundo para solucionar
                    problemas y tomar decisiones acertadas con alto sentido ético.
                  </p>
                </div>
              </div>

              <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-chat-square-heart flex-shrink-0"></i>
                <div>
                  <h4>La Lealtad:</h4>
                  <p>
                    La Institución permanece firme en el cumplimiento de sus declaraciones
                    institucionales y define aquello en lo que cree con autenticidad y orgullo, reitera su
                    vocación de servicio a través del respaldo y la confianza de las personas que
                    constituyen su comunidad educativa.
                  </p>
                </div>
              </div>

              <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-signpost-split flex-shrink-0"></i>
                <div>
                  <h4>El Respeto:</h4>
                  <p>
                    Al interior de la Institución se reconoce y respeta la diferencia de las ideas,
                    la pluralidad y la tolerancia, como enclaves básicos de la convivencia armónica y
                    pacífica.
                  </p>
                </div>
              </div>


              <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-chat-square-heart flex-shrink-0"></i>
                <div>
                  <h4>Disciplina:</h4>
                  <p>
                  La Institución reconoce y asume que solo siendo disciplinados se logran los objetivos, propende por la calidad 
                  y exige calidad que se da solo con un trabajo estructurado, serio y de compromiso que demuestra el profesionalismo de cada 
                  colaborador, así como el profesionalismo de nuestros egresados.
                  </p>
                </div>
              </div>



            </div>
            
            <div className="col-lg-6 img-bg" style={{backgroundImage: `url(${info1})`}} data-aos="zoom-in" data-aos-delay="100"></div>
          </div>
        </div>
      </section>
  


      </>
     
    )
}


export default QuienesSomos