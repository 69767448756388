import React from "react";
import'../css/facultades.css';
import bannercomunicaion from '../../src/img/facultades/bg-comunicacion.jpeg';
import {FacultadesCard} from'../componentes/facultades/FacultadesCard';
import comuSocial from '../img/facultades/comunicacion.jpg';
import diseno from '../img/facultades/diseno.jpg';
import mercadeo from '../img/facultades/mercadeo.jpg';





const FacultadComunicacion = () => {

    const FacultadComunicacion = [
        {
          title: "Comunicación Social",
          imgUrl: comuSocial,
          snies: "SNIES 106552",
          semestre:"9 Semestres",
          link: "/facultades/facultadComunicacion/comunicacionSocial",
        },
        {
          title: "Diseño Gráfico",
          imgUrl: diseno,
          snies: "SNIES 107917",
          semestre:"8 Semestres",
          link:"/facultades/facultadComunicacion/diseno"
        },
        {
          title: "Mercadeo",
          imgUrl: mercadeo,
          snies: "SNIES 109375",
          semestre:"8 Semestres",
          link:"/facultades/facultadComunicacion/mercadeo"
        },
      ];

    return(
       <>
    <div className="breadcrumbs d-flex align-items-center" style={{backgroundImage:`url(${bannercomunicaion})`}}>
        <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
        </div>
        </div>

   
    <section id="facultades" className="facultades">
      <div className="container" data-aos="fade-up">

        <div className="row position-relative">


          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
            <h2>Facultad de Comunicación, Arte y Marketing Digital</h2>

            <p>
            La Facultad de Comunicación, Arte y Marketing Digital brinda a los estudiantes múltiples dimensiones de la expresión creativa y la interacción mediática. En un entorno académico que fomenta la experimentación y la colaboración, nuestros programas están diseñados para preparar a los estudiantes para enfrentar los desafíos dinámicos del mundo moderno. Ofrecemos una formación integral que abarca desde las técnicas de comunicación y medios digitales hasta el diseño visual y las artes plásticas, promoviendo un enfoque multidisciplinario que capacita a nuestros egresados para liderar en sus respectivos campos con creatividad, pensamiento crítico y habilidades prácticas. En esta facultad, cada proyecto es una oportunidad para explorar nuevas fronteras y cada clase es una puerta abierta a la innovación y el descubrimiento            
            </p>


        </div>

        <div className="row gy-5 facultades">
        {
                          FacultadComunicacion.map((div, index) => {
                            return (
                              <FacultadesCard
                                key={index}
                                {...div}
                                />
                            )
                          })
                        }

        </div>
        </div>
        </div>
        </section>
        </>
    )
}

export default FacultadComunicacion